var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-container" },
    [
      _vm.isCredit
        ? _c("div", { staticClass: "credit-label" }, [_vm._v("Credit")])
        : _vm._e(),
      _c("el-form", { ref: "lineitem_form", attrs: { model: _vm.form } }, [
        _c(
          "div",
          { staticClass: "item-row" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 5, type: "flex", align: "middle" } },
              [
                _c("el-col", { attrs: { span: _vm.descriptionWidth } }, [
                  _c("div", { staticClass: "row description" }, [
                    _c(
                      "span",
                      { staticClass: "el-input__inner input-value read-only" },
                      [_vm._v(" " + _vm._s(_vm.line_item.description) + " ")]
                    ),
                  ]),
                ]),
                _vm.draft
                  ? _c("el-col", { attrs: { span: 3 } }, [
                      _c("div", { staticClass: "row total" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "el-input__inner input-value read-only",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.total * _vm.sign)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.draft
                  ? _c("el-col", { attrs: { span: 2 } }, [
                      _c(
                        "div",
                        { staticClass: "row percent" },
                        [
                          _vm.total > 0
                            ? _c("el-input-number", {
                                ref: "input_percent",
                                staticClass: "input-value",
                                attrs: {
                                  controls: false,
                                  size: "small",
                                  placeholder: "0",
                                  min: 0,
                                  max: 100,
                                  precision: 0,
                                  step: 5,
                                },
                                on: {
                                  change: _vm.validatePercent,
                                  focus: _vm.selectInput,
                                },
                                model: {
                                  value: _vm.form.percent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "percent", $$v)
                                  },
                                  expression: "form.percent",
                                },
                              })
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "el-input__inner input-value read-only",
                                },
                                [_vm._v(" - ")]
                              ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.plan_total
                  ? _c("el-col", { attrs: { span: 2 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "row plan_total",
                          class: _vm.planTotalClass,
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.form.percent.toFixed()) + "% "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.plan_total
                  ? _c("el-col", { attrs: { span: 3 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "row plan_total",
                          class: _vm.planTotalClass,
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(_vm.planTotalDifference)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _c("el-col", { attrs: { span: 3 } }, [
                      _c(
                        "div",
                        { staticClass: "row amount" },
                        [
                          _vm.isCredit
                            ? _c("span", { staticClass: "credit-prefix" }, [
                                _vm._v(" - "),
                              ])
                            : _vm._e(),
                          _c(
                            "money",
                            _vm._b(
                              {
                                ref: "input_amount",
                                staticClass: "el-input__inner",
                                attrs: {
                                  readonly: !_vm.draft || _vm.total === 0,
                                },
                                nativeOn: {
                                  blur: function ($event) {
                                    return _vm.validateAmount.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.selectInput.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "amount", $$v)
                                  },
                                  expression: "form.amount",
                                },
                              },
                              "money",
                              _vm.money,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }