var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      !(_vm.reminders && _vm.reminders.length) && !_vm.show_form
        ? _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: () => {
                  _vm.show_form = true
                },
              },
            },
            [_vm._v(" Add reminder ")]
          )
        : _c(
            "div",
            { staticClass: "reminders" },
            [
              _c(
                "div",
                { staticClass: "reminders-header" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 5, type: "flex", align: "middle" } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _vm._v(" Reminders "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.reminders, function (reminder, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "reminders-row" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 5, type: "flex", align: "middle" } },
                      [
                        _c("el-col", { attrs: { span: 5 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "value",
                              staticStyle: { "padding-left": "30px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateformat")(
                                      reminder.date,
                                      "DD/MM/YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("el-col", [
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getUser(reminder.user).name) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 1 } },
                          [
                            _vm.canDelete(reminder)
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "Delete this reminder?" },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.handleDeleteReminder(
                                          reminder
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "delete",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            icon: "el-icon-circle-close",
                                            type: "danger",
                                            plain: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "reminders-row" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 5, type: "flex", align: "middle" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              size: "small",
                              placeholder: "Reminder date",
                              format: "dd/MM/yyyy",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.form.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "date", $$v)
                              },
                              expression: "form.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.form.user_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "user_id", $$v)
                                },
                                expression: "form.user_id",
                              },
                            },
                            _vm._l(_vm.users, function (u) {
                              return _c(
                                "el-option",
                                {
                                  key: u.id,
                                  attrs: { value: u.id, label: u.name },
                                },
                                [_vm._v(" " + _vm._s(u.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c("el-button", {
                            attrs: {
                              disabled: !_vm.is_valid,
                              icon: "el-icon-plus",
                            },
                            on: { click: _vm.handleAddReminder },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }